// Note: breakpoints and containers are used in several places, including
// useBreakpoint and SiteImage. When updating, double check all uses.

// Breakpoints xs-xxl
$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;

// Map for iterators
$breakpoints: (
  xs: $breakpoint-xs,
  sm: $breakpoint-sm,
  md: $breakpoint-md,
  lg: $breakpoint-lg,
  xl: $breakpoint-xl,
  xxl: $breakpoint-xxl,
);

// Containers xs-xxl
$container-xs: 100%;
$container-sm: 548px;
$container-md: 704px;
$container-lg: 840px;
$container-xl: 972px;
$container-xxl: 1044px;

// Map for iterators
$containers: (
  xs: $container-xs,
  sm: $container-sm,
  md: $container-md,
  lg: $container-lg,
  xl: $container-xl,
  xxl: $container-xxl,
);

// Gutters xs-xxl
$gutter: 16px;
