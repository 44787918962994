@import '/src/styles/utilities.scss';

.additionalFeatureContent {
  padding-top: $space-40;
  padding-bottom: $space-40;
  background-color: var(--color-blue-100);
}

.additionalFeatureContent__title {
  @include create-container();
}

.additionalFeatureContent__name {
  font-weight: $font-weight-regular;
}
