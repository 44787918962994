@import '/src/styles/utilities.scss';

$card-deck-highlight-image: 450px;

.highlightCategory {
  display: grid;
  grid-template-areas: 'overlay';
  margin-bottom: $space-48;
  overflow: hidden;

  @include media-breakpoint-between(xs, md) {
    // This accounts for the CardDeckHighlight image that is supposed to
    // overflow the container while the rest of the CardDeckHighlight is
    // contained within the container.
    $image-overflow: min(75vw, $card-deck-highlight-image);

    padding-top: calc($image-overflow - $space-96);
  }

  @include media-breakpoint-up(md) {
    padding-top: $space-96;
  }
}

.highlightCategory__background {
  position: relative;
  grid-area: overlay;
  height: 100%;
  background: var(--color-blue-100);

  // Create a rounded top edge for the background
  @include media-breakpoint-up(md) {
    &:before {
      position: absolute;
      top: $space-20;
      right: -$space-20;
      left: -$space-20;
      height: $space-96 * 2;
      clip-path: ellipse(50% 50% at 50% 50%);
      content: '';
      background-color: var(--color-blue-100);
      transform: translateY(-50%);
    }
  }
}

.highlightCategory__content {
  @include create-container();

  display: flex;
  flex-direction: column;
  grid-area: overlay;
  gap: $space-48;
  padding-top: $space-20;
  padding-bottom: $space-48;

  @include media-breakpoint-only(sm) {
    padding-top: $space-48;
  }
}
