@import '/src/styles/utilities.scss';

.showCategoryPreview__sectionHeading {
  margin-bottom: $space-responsive-28;

  @include media-breakpoint-up(lg) {
    margin-bottom: $space-responsive-40;
  }
}

.showCategoryPreview__sectionTitle {
  color: var(--color-gocomics-blue);
}

.showCategoryPreview__row {
  margin-bottom: $space-40;
}
