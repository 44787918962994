@use 'sass:map';
@import '/src/styles/utilities.scss';

.cardDeck {
  @include create-container();

  &_hasAdvertisement {
    display: flex;
    flex-direction: column;
    gap: $gutter;

    // The ad is desktop only
    @include media-breakpoint-up(lg) {
      display: grid;
      grid-template-columns: 1fr $width-ad-rail;
    }
  }
}

.cardDeck__content {
  display: flex;
  flex-direction: column;
  gap: $space-12;
  margin-bottom: $space-12;
}

.cardDeck__cardGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: $space-16;

  // 7th, 8th and so on should be hidden
  & > :nth-child(n + 7) {
    display: none;
  }
}
